@import url("https://use.typekit.net/ret0ejy.css");

:root {
    --tui-font-family-sans-serif: 'proxima-nova', sans-serif;
    --tui-font-family-heading: 'freight-big-pro', sans-serif;

    --tui-color-background-scrollbar: transparent;
    --tui-color-foreground-scrollbar: #cccccc;
    --tui-width-scrollbar: 0.5rem;
    --tui-radius-scrollbar-border: 0.25rem;

    --tui-font-weight-light: 300;
    --tui-font-weight-medium: 400;
    --tui-font-weight-bold: 700;
    --tui-font-weight-heading: 900;

    --tui-font-size-extra-small: 0.7rem;
    --tui-font-size-small: 0.8rem;
    --tui-font-size-medium: 1rem;
    --tui-font-size-large: 1.26rem;
    --tui-font-size-extra-large: 1.6rem;
    --tui-font-size-super-large: 2.2rem;
    --tui-font-size-massive-large: 2.8rem;
    --tui-font-size-mega-large: 3rem;
    --tui-font-size-ultra-large: 4rem;

    --tui-spacing-ultra-small: 0.1rem;
    --tui-spacing-extra-extra-small: 0.3rem;
    --tui-spacing-extra-small: 0.7rem;
    --tui-spacing-small: 0.8rem;
    --tui-spacing-medium: 1rem;
    --tui-spacing-large: 1.26rem;
    --tui-spacing-extra-large: 1.6rem;
    --tui-spacing-super-large: 2.2rem;
    --tui-spacing-mega-large: 3rem;
    --tui-spacing-ultra-large: 4rem;

    --tui-color-primary: #193256;
    --tui-color-accent: rgb(65, 131, 196);
    --tui-color-brand: #193256;
    --tui-color-brand-hover: #06179E;

    --tui-color-text: rgb(100,99,99);
    --tui-filter-background-color: #EFF4FA;
    --tui-color-brand-light: #525f7e;

    --tui-color-label: rgb(100,99,99);
    --tui-color-description: rgb(100,99,99);
    --tui-color-watermark: rgb(100,99,99);

    --tui-color-background: #eff4fa;
    --tui-color-background-information: var(--tui-color-background);

    --color-foreground: #ffffff;
    --color-background: #eff4fa;
    --color-background-dark: #193256;
    --color-text-on-dark: #ffffff;
    --color-text: #333333;

    --color-orange: #ef7d00;
    --color-blue: #018eca;
    --color-green: rgb(41, 181, 115);

    --color-conflict: var(--color-orange);
    --color-disaster: var(--color-blue);
    --color-other: var(--color-green);

    --width-page-max: 72rem;
    --width-readable-content-max: 46rem;

    --tui-duration-transition-short: 100ms;
    --tui-duration-transition-medium: 200ms;
    --tui-duration-transition-long: 400ms;

    --tui-duration-delay-short: 150ms;
    --tui-duration-delay-medium: 250ms;
    --tui-duration-delay-long: 400ms;

    --tui-radius-border-input-section: 0;
    --tui-radius-border-alerts: 0.24rem;

    --tui-radius-border-large: 0.8rem;
    --color-progress-line-background: #f0f0f0;
}

.base {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* country profile should take some space initially */
    line-height: 1.5;
    color: var(--color-text);
    font-family: 'proxima-nova', sans-serif;
    font-size: 1rem; /* durpal has set font-size on body */

    .view {
        flex-grow: 1;
    }

    .alert-container {
        position: fixed;
        right: var(--tui-spacing-large);
        bottom: var(--tui-spacing-large);
        z-index: 1000;
        width: 20rem;
    }
}

/* FIXME: We should not override style of elements */
a {
    text-decoration: none;
}

/* FIXME: We should not override style of elements */
h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}


/* NOTE: Unset font-size because drupal has set a font-size of 10px */
html {
    font-size: unset;
}
