.page {
    display: flex;
    flex-direction: column;
    gap: var(--tui-spacing-large);
    padding: var(--tui-spacing-large);

    .filters {
        display: flex;
        flex-wrap: wrap;
        gap: var(--tui-spacing-medium);
    }

    .sections {
        display: flex;
        flex-wrap: wrap;
        gap: var(--tui-spacing-medium);

        .section {
            display: flex;
            flex-basis: calc(100% / 4 - 3 * var(--tui-spacing-medium) / 4);
            flex-direction: column;
            gap: var(--tui-spacing-small);
            background-color: var(--tui-color-background);
            padding: var(--tui-spacing-medium);

            max-height: 500px;
            overflow: auto;

            .items {
                display: flex;
                flex-direction: column;
                overflow: auto;
            }
        }
    }
}
