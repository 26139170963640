.figure-analysis {
    display: block;

    .header-container {
        .heading {
            color: var(--color-text-on-dark);
        }

        &.conflict {
            background-color: var(--color-conflict);
        }
        &.disaster {
            background-color: var(--color-disaster);
        }
    }

    .children {
        display: flex;
        flex-direction: column;
        border: var(--tui-width-separator-thin) solid var(--tui-color-separator);
        border-top: 0;

        .details {
            .collapsible-children {
                padding-bottom: var(--tui-spacing-medium);
            }
            &:not(:first-child) {
                border-top: var(--tui-width-separator-thin) solid var(--tui-color-separator);
            }
        }
    }
}
