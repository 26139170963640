.body-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: transparent;

    .gidd {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: var(--tui-spacing-extra-large);
    }

    .filter-container {
        border-radius: var(--tui-radius-border-large);
        background-color: var(--tui-color-brand);
        padding: var(--tui-spacing-medium);
        color: var(--color-text-on-dark);

        .reset-button {
            padding: var(--tui-spacing-small);
            color: var(--tui-color-text-on-dark);

            &:hover {
                opacity: 0.9;
                color: var(--tui-color-text-on-dark);
            }
        }

        .filter-body-container {
            display: flex;
            flex-wrap: wrap;
            gap: var(--tui-spacing-large);

            .left-section {
                display: flex;
                flex-basis: calc(100% / 3 - 2 * var(--tui-spacing-large) / 3);
                flex-direction: column;
                flex-grow: 1;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: var(--tui-spacing-large);

                &.left-section {
                    justify-content: flex-start;
                }

                @media screen and (max-width: 720px) {
                    flex-basis: 100%;
                }
            }

            .right {
                display: flex;
                align-self: flex-start;
                flex-basis: calc(100% / 3 * 2 - 2 * var(--tui-spacing-large) / 3);
                flex-direction: column;
                flex-grow: 1;
                gap: var(--tui-spacing-medium);

                .top {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    gap: var(--tui-spacing-large);

                    .filter-section {
                        display: flex;
                        align-items: flex-end;
                        flex-wrap: wrap;
                        gap: var(--tui-spacing-large);

                        .filter-input {
                            flex-basis: calc(100% / 2 - var(--tui-spacing-large));

                            @media screen and (max-width: 720px) {
                                flex-basis: 100%;
                            }
                        }

                        .input {
                            background-color: var(--tui-color-background);
                        }

                        .slider-input {
                            border-radius: 0.25rem;
                            padding: var(--tui-spacing-extra-extra-small);
                        }
                    }
                }

                @media screen and (max-width: 720px) {
                    flex-basis: 100%;
                }

                .switch {
                    color: var(--tui-color-foreground);

                    .switch-label {
                        padding: 0;
                        color: var(--tui-color-foreground);
                        font-size: var(--tui-font-size-small);
                    }
                }

                .disaster-filters {
                    display: flex;
                    flex-wrap: wrap;
                    gap: var(--tui-spacing-large);

                    >* {
                        flex-basis: calc(50% - var(--tui-spacing-large) / 2);
                    }
                }
            }

            .heading-description {
                font-size: var(--tui-font-size-small);
            }

            .download-section {
                display: flex;
                flex-direction: column;
                border-radius: var(--tui-radius-border-large);
                background-color: var(--tui-color-background);
                padding: var(--tui-spacing-medium);
                gap: var(--tui-spacing-small);

                .download-description {
                    color: #000;
                    font-size: var(--tui-font-size-extra-small);

                }
            }
        }
    }

    .stats-container {
        display: flex;
        width: 100%;
        gap: var(--tui-spacing-extra-large);

        .stat-box {
            display: flex;
            flex-basis: calc(50% - var(--tui-spacing-extra-large) / 2);
            flex-direction: column;
            border: var(--tui-width-separator-thin) solid var(--tui-color-separator);
            border-radius: var(--tui-radius-border-large);
            background-color: var(--tui-color-foreground);
            padding: var(--tui-spacing-large);
            max-width: calc(50% - var(--tui-spacing-extra-large) / 2);
            gap: var(--tui-spacing-medium);

            .causes-block {
                display: flex;
                gap: var(--tui-spacing-medium);
            }

            .chart-type-container {
                display: flex;
                gap: var(--tui-spacing-small);

                .switch {
                    align-items: flex-start;
                    gap: var(--tui-spacing-extra-extra-small);

                    .knob {
                        margin: 0;
                    }

                    .switch-label {
                        padding: 0;
                        font-size: var(--tui-font-size-small);
                    }
                }
            }

            .chart-container {
                width: 100%;
                height: 16rem;

                .select-input {
                    --tui-spacing-super-small: 1px;
                    --tui-spacing-extra-small: 3px;
                    --tui-spacing-small: 6px;
                    --tui-spacing-small-medium: 8px;
                    --tui-spacing-medium: 10px;
                    --tui-spacing-large-medium: 13px;
                    --tui-spacing-large: 16px;
                    --tui-spacing-extra-large: 24px;
                }
            }

            .top-stats {
                display: flex;
                flex-direction: column;
                gap: var(--tui-spacing-medium);
            }

            &.only-one-selected {
                flex-basis: 100%;
                max-width: 100%;

                &.disaster-stat-box {
                    display: flex;
                    flex-direction: row;
                    gap: var(--tui-spacing-extra-large);

                    .top-stats,
                    .disaster-stats {
                        flex-basis: calc(50% - var(--tui-spacing-extra-large) / 2);
                        max-width: calc(50% - var(--tui-spacing-extra-large) / 2);
                    }
                }
            }
        }

        @media (max-width: 720px) {
            flex-direction: column;

            .stat-box {
                max-width: 100%;

                &.only-one-selected {
                    &.disaster-stat-box {
                        flex-direction: column;

                        .top-stats,
                        .disaster-stats {
                            flex-basis: 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }

    .table-container {
        display: flex;
        flex-direction: column;
        border: var(--tui-width-separator-thin) solid var(--tui-color-separator);
        border-radius: var(--tui-radius-border-large);
        background-color: var(--tui-color-foreground);
        gap: var(--tui-spacing-medium);

        .header {
            padding-top: var(--tui-spacing-large);
        }

        .tab-panels {
            padding: 0 var(--tui-spacing-large);
        }
    }

    .select-input {
        --tui-spacing-super-small: 1px;
        --tui-spacing-extra-small: 3px;
        --tui-spacing-small: 6px;
        --tui-spacing-small-medium: 8px;
        --tui-spacing-medium: 10px;
        --tui-spacing-large-medium: 13px;
        --tui-spacing-large: 16px;
        --tui-spacing-extra-large: 24px;

        .input-section {
            border-radius: 0.25rem;
            background-color: var(--tui-filter-background-color);
        }
    }
}

:global {
    .recharts-tooltip-wrapper {
        width: 12rem;

        .recharts-default-tooltip {
            width: 100%;
            white-space: unset!important;
            font-size: var(--tui-font-size-small);
        }
    }
}
