.tab {
    border: 0;
    padding: var(--tui-spacing-medium) var(--tui-spacing-large);
    color: var(--tui-color-text);
    font-weight: var(--tui-font-weight-bold);

    &.disabled {
        pointer-events: none;
    }

    &.primary {
        position: relative;
        background-color: var(--tui-color-background);
        font-size: var(--tui-font-size-large);

        .active-border {
            position: absolute;
            bottom: 0;
            left: var(--tui-spacing-small);
            background-color: var(--tui-color-);
            width: calc(100% - #{var(--tui-spacing-medium)});
            height: var(--tui-width-separator-thin);
            animation: grow-h .2s ease-in;
        }

        &.active {
            background-color: var(--tui-color-foreground);
            color: var(--tui-color-brand);
        }

        &.disabled {
            color: var(--tui-color-text-disabled);

            .active-border {
                background-color: var(--tui-color-separator);
            }
        }
    }

    &.secondary {
        border-radius: 0.25rem;

        background-color: var(--tui-color-background);
        text-align: left;
        text-transform: uppercase;

        &.active {
            background-color: var(--tui-color-brand);
            color: var(--tui-color-text-on-dark);
        }

        &.disabled {
            color: var(--tui-color-text-disabled);
        }
    }

    &.step {
        position: relative;
        transition: var(--tui-duration-transition-medium) color, background-color ease-in-out;
        padding: var(--tui-spacing-medium);
        text-transform: uppercase;
        color: var(--tui-color-text-light);

        &:hover {
            color: var(--tui-color-text);
        }

        &.active {
            color: var(--tui-color-brand);
        }

        &.disabled {
            color: var(--tui-color-text-disabled);
        }
    }

    &.errored {
        position: relative;

        .error-icon {
            position: absolute;
            transform: translate(-100%, -100%);
            border-radius: 50%;
            background-color: var(--tui-color-negative);
            width: .75em;
            height: .75em;
        }
    }
}

.primary-tab-wrapper {
    border: var(--tui-width-separator-thin) solid transparent;
    border-bottom-color: var(--tui-color-separator);
    padding: 0 var(--tui-spacing-small);

    &.active {
        border-color: var(--tui-color-separator);
        border-bottom-color: transparent;
    }
}

@keyframes grow-h {
    from { transform: scaleX(0); }
    to { transform: scaleX(100%); }
}

.step-tab-wrapper {
    display: flex;
    align-items: center;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;

    .step-circle {
        transition: var(--tui-duration-transition-slow) border-color ease-in-out;
        border: var(--tui-width-separator-medium) solid transparent;
        border-radius: 50%;
        background-color: var(--tui-color-background);
        padding: var(--tui-spacing-extra-small);

        .inner-circle {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: var(--tui-duration-transition-slow) background-color ease-in-out;
            border-radius: 50%;
            background-color: var(--tui-color-text-disabled);
            width: 1rem;
            height: 1rem;

            .icon {
                padding: 0.1em;
                color: var(--tui-color-text-on-dark);
            }
        }
    }

    &.completed {
        .step-circle {
            .inner-circle {
                background-color: var(--tui-color-text);
            }
        }
    }

    &.active {
        .step-circle {
            border-color: var(--tui-color-brand);

            .inner-circle {
                background-color: var(--tui-color-brand);
            }
        }
    }

    &.disabled {
        .step-circle {
            opacity: 0.5;
            filter: saturate(50%);
        }
    }
}
