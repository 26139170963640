.dismissable-list-output {
    display: flex;
    align-items: baseline;
    gap: var(--tui-spacing-small);
    flex-wrap: wrap;

    .label {
        font-weight: var(--tui-font-weight-bold);
    }

    .value-list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--tui-spacing-small);

        .chip-content {
            padding: 0 var(--tui-spacing-extra-small);
        }

        .chip-action {
            padding: 0;

            .chip-action-icons {
                padding: 0;
            }

            .chip-remove-button {
                padding: var(--tui-spacing-extra-small);
            }
        }
    }
}
