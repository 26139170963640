.internal-displacement-updates {
    display: flex;
    flex-direction: column;
    gap: var(--tui-spacing-large);

    .idu-container {
        display: flex;
        flex-wrap: wrap;
        gap: var(--tui-spacing-large);
        padding: var(--tui-spacing-large) 0;

        .idu {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            flex-grow: 1;
            border: var(--tui-width-separator-thin) solid var(--tui-color-separator);
            padding: var(--tui-spacing-medium);
            max-width: calc(50% - var(--tui-spacing-medium));
            gap: var(--tui-spacing-medium);

            @media screen and (max-width: 900px) {
                max-width: unset;
            }

            .displacementIcon {
                display: flex;
                align-items: baseline;
                gap: var(--tui-spacing-medium);

                .icon {
                    flex-shrink: 0;
                    height: 2rem;
                }
            }
        }

        .idu-pager {
            display: flex;
            flex-grow: 1;
            flex-wrap: wrap;
            gap: var(--tui-spacing-large);
        }

        .map-description {
            flex-basis: 100%;
        }
    }
}
