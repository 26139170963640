.map-container {
    width: 100%;
    height: 32rem;
}

.map-popup {
    display: flex;
    padding: 0;
    width: 32rem;
    height: 16rem;
    overflow: auto;
    font-family: var(--tui-font-family-sans-serif);
    font-size: var(--tui-font-size-small);

    .item {
        display: flex;
        gap: var(--tui-spacing-extra-small);
        align-items: baseline;

        .icon {
            flex-shrink: 0;
            border-radius: 50%;
            background-color: gray;
            width: 0.6rem;
            height: 0.6rem;

            &.conflict {
                background-color: var(--color-conflict);
            }

            &.disaster {
                background-color: var(--color-disaster);
            }
        }

        .description {
            flex-grow: 1;
        }
    }
}

:global {
    .mapboxgl-tip {
        flex-shrink: 0;
    }

    .mapboxgl-popup-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: var(--tui-spacing-small) 0;
        overflow: auto;

        >div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: 0 var(--tui-spacing-small);
            overflow: auto;
            gap: var(--tui-spacing-small);
        }
    }
}
